import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'

/** @notExported */
interface EditButtonProps {
  /** Action to perform when button is clicked. */
  clickAction: () => void
  /** Tooltip to display. */
  tooltip?: string
  /** Whether or not the button is valid. */
  valid?: boolean
  /** Size of the button. */
  size?: 'medium' | 'small' | undefined | 'xsmall'
  /** Whether or not to float the button. */
  noFloat?: boolean
}

/**
 * Edit button component.
 *
 * @returns Edit button.
 * @notExported
 * @category Buttons
 */
const EditButton: React.FC<EditButtonProps> = ({
  clickAction,
  tooltip,
  valid = true,
  size = 'small',
  noFloat = false,
}) => {
  const { t } = useTranslation()

  const getButton = () => {
    return (
      <IconButton
        sx={noFloat ? { float: 'none' } : { float: 'right' }}
        onClick={event => {
          event.stopPropagation()
          clickAction()
        }}
        disabled={!valid}
        size={size === 'xsmall' ? 'medium' : size}
      >
        <EditIcon fontSize={size === 'xsmall' ? 'small' : undefined} />
      </IconButton>
    )
  }
  return <Tooltip title={tooltip ?? (t('edit') as string)}>{getButton()}</Tooltip>
}

export default EditButton
