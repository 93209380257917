import { Grid, Typography } from '@mui/material'
import { teamAPI } from 'api/TeamAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import EditButton from 'Components/reusable/IconButtons/EditButton'
import { useUser } from 'hooks'
import { useCountryData } from 'hooks/data'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IError } from 'types/error'
import { ITeam, ITeamData } from 'types/teamInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import DataChips from './DataChips'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import { useIsComponentMounted } from 'hooks/util'
import colors from 'constants/colors'
import ResultContent from 'pages/Search/Results/ResultContent'
import { getCV } from 'utils/getCV'
import { PersonId } from 'types/ids'
import { ISearchResult } from 'types/searchInterfaces'
import { ToolbarItems } from 'Components/General/Toolbar'
import BackButton from 'Components/reusable/IconButtons/BackButton'
import BorderedSection from 'Components/reusable/CaleoCustomComponents/BorderedSection'

/** @notExported */
interface ITeamViewModalProps {
  /** The team. */
  item: ITeam
  /** Whether the user is an admin account. */
  adminAccount: boolean
  /** Whether the user has manager access. */
  managerAccess: boolean
  /** A function to close the modal. */
  onClose: () => void
  /** A function to set the edited item. */
  setEdit: (item: ITeam) => void
}

/**
 * The TeamViewModal component.
 *
 * @param item - The team.
 * @param adminAccount - Whether the user is an admin account.
 * @param managerAccess - Whether the user has manager access.
 * @param onClose - Callback function to close the modal.
 * @param setEdit - A function to set the edited item.
 * @param images - The images.
 * @returns The TeamViewModal component.
 * @notExported
 */
const TeamViewPage: React.FC<ITeamViewModalProps> = ({ item, adminAccount, managerAccess, onClose, setEdit }) => {
  const isComponentMounted = useIsComponentMounted()
  const { user, features } = useUser()
  const { t, i18n } = useTranslation()
  const { countries } = useCountryData()
  const [backendError, setBackendError] = useState<IError>()
  const [detailedTeam, setDetailedTeam] = useState<ITeam>()
  const [teamData, setTeamData] = useState<ITeamData>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        const team = await teamAPI.getTeamById(item.id, controller)
        const data = await teamAPI.getTeamData(item.id, controller)
        if (isComponentMounted.current) {
          setDetailedTeam(team)
          setTeamData(data)
        }
      } catch (err) {
        setBackendError(err as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [item])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const isTeamManager = () => {
    if (user) {
      // if user is in item.TeamMembers and manager is true
      if (item.TeamMembers.find(member => member.Account.id === user.id && member.manager)) {
        return true
      }
    }
    return false
  }

  const teamName = detailedTeam ? chooseDBTranslation(i18n, detailedTeam).name : ''

  return (
    <Grid container>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs>
          <Typography variant="h1" gutterBottom fontWeight="bold">
            {t('team')}
          </Typography>
        </Grid>
        {(adminAccount || managerAccess) && detailedTeam && (
          <Grid item xs>
            <EditButton
              clickAction={() => {
                setEdit(detailedTeam)
              }}
              size="medium"
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          {t('team.info')}
        </Typography>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={2}>
            <Grid item xs={12}>
              <CardContentText type={'header'}>{t('team.name')}</CardContentText>
            </Grid>
            <Grid item xs={12}>
              {teamName}
            </Grid>
          </Grid>
          {teamData && (
            <>
              {teamData.skills.length > 0 && (
                <Grid item xs={2}>
                  <Grid item xs={12}>
                    <CardContentText type={'header'}>{t('team.skills')}</CardContentText>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <DataChips data={teamData.skills} type="skill" />
                  </Grid>
                </Grid>
              )}
              {teamData.roles.length > 0 && (
                <Grid item xs={2}>
                  <Grid item xs={12}>
                    <CardContentText type={'header'}>{t('team.roles')}</CardContentText>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <DataChips data={teamData.roles} type="role" />
                  </Grid>
                </Grid>
              )}
              {teamData.industries.length > 0 && (
                <Grid item xs={2}>
                  <Grid item xs={12}>
                    <CardContentText type={'header'}>{t('team.industries')}</CardContentText>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <DataChips data={teamData.industries} type="industry" />
                  </Grid>
                </Grid>
              )}
              {teamData.certificates.length > 0 && (
                <Grid item xs={2}>
                  <Grid item xs={12}>
                    <CardContentText type={'header'}>{t('team.certificates')}</CardContentText>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <DataChips data={teamData.certificates} type="certificate" />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <CardContentText type={'header'}>{t('team.description')}</CardContentText>
            </Grid>
            <Grid item xs={12}>
              {chooseDBTranslation(i18n, item).description}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <CardContentText type={'header'}>
                {features.includes('workOrders') ? t('team.limitWorkOrders') : t('team.limitAssignments')}
              </CardContentText>
            </Grid>
            <Grid item xs={12}>
              {item.limitAssignments ? t('yes') : t('no')}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            {t('team.members')}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          {detailedTeam?.TeamMembers.map(member => {
            if (member.Account?.Person) {
              const cv = getCV(member.Account?.Person)

              const result: ISearchResult = {
                kind: 'partial',
                Person: member.Account?.Person,
                anonymous: false,
                personId: member.Account?.Person.id as PersonId,
              }

              if (member.manager) {
                return (
                  <Grid key={member.id} item xs={6}>
                    <Grid item xs={12}>
                      <BorderedSection color={colors.green} title={t('team.modal.manager')} noMargin>
                        <ResultContent
                          result={result}
                          cv={cv}
                          countries={countries}
                          disableAction={(!adminAccount || !isTeamManager()) && !member.hasAccess}
                          modal
                        />
                      </BorderedSection>
                    </Grid>
                  </Grid>
                )
              }

              return (
                <Grid key={member.id} item xs={6}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      border: `1px solid ${colors.borderColor}`,
                      borderRadius: 1,
                      padding: '1em',
                    }}
                  >
                    <ResultContent
                      result={result}
                      cv={cv}
                      countries={countries}
                      disableAction={(!adminAccount || !isTeamManager()) && !member.hasAccess}
                      modal
                    />
                  </Grid>
                </Grid>
              )
            }
            return null
          })}
        </Grid>
      </Grid>
      <ToolbarItems>
        <BackButton clickAction={() => onClose()} />
      </ToolbarItems>
    </Grid>
  )
}

export default TeamViewPage
