import React, { useContext, useEffect, useState } from 'react'
import HelpButton from 'Components/reusable/IconButtons/HelpButton'
import { userManualAPI } from 'api/UserManualAPI'
import { IUserManualPage } from 'types/userManualInterfaces'
import HelpModal from './HelpModal'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

interface HelpButtonApi {
  setUrl: (url: string) => void
}

const HelpButtonContext = React.createContext<HelpButtonApi>({} as HelpButtonApi)

const { Provider } = HelpButtonContext

/**
 * Help button provider.
 *
 * @param children
 * @returns Provider for showing help button in context.
 */
export const HelpButtonProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation()
  const [url, setUrl] = useState<string>()
  const [open, setOpen] = useState<boolean>(false)
  const [article, setArticle] = useState<IUserManualPage>()
  const location = useLocation()

  useEffect(() => {
    ;(async () => {
      if (url) {
        const article = await userManualAPI.getUserManualPageByUrl(url)
        if (article && typeof article !== 'string') {
          setArticle(article)
        } else {
          setArticle(undefined)
        }
      } else {
        setUrl(undefined)
      }
    })()
  }, [url, location.pathname])

  return (
    <Provider value={{ setUrl }}>
      {children}
      {!open && article && <HelpButton onChange={setOpen} />}
      {open && article && (
        <HelpModal
          open={open}
          onClose={() => setOpen(false)}
          header={t('help')}
          maxWidth={'xl'}
          fullWidth={true}
          article={article}
          backgroundColor={'#fff'}
        />
      )}
    </Provider>
  )
}

/**
 * Hook for displaying help button.
 *
 * @returns Help button provider context variables and functions.
 */
export const useHelpButton = () => {
  return useContext(HelpButtonContext)
}
